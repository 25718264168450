@import "variables";

.LeftSidebar-Wrapper {
  border-right: 1px solid lightgray;
}

.category-tree {
  span.category-item:hover {
    cursor: pointer;
    background: rgb(255, 255, 255);
    background: $category-item-hover-gradient;
  }
}

.cat-caret {
  float: right;
  margin-right: 10px;
  font-weight: 700;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid #666;
  transform: rotate(-45deg);
  margin-top: 2px;
  &:not(.active) {
    border-right: 1px solid #666;
  }

  &.active {
    border-left: 1px solid #666;
  }
}

.breadcumb {
  .breadcumb__element:last-child {
    font-weight: 700;
    color: #615e58;
  }
  .breadcumb__element:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    margin: 0 10px;
    transform: rotate(-45deg);
    z-index:-999;
  }
}
