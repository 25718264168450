.category-grid {
  display: grid;
  grid-gap: 7px;
  grid-template-columns: 100%;
  width: 90%;

  @media screen and (min-width:767.98px) {
  grid-template-columns: repeat(2, 50%);
  }

  @media screen and (min-width:991.98px) {
    grid-template-columns: repeat(3, 33%);

  }
}