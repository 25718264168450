@import "variables";

.place-order-btn{
    background-color: $secondary-bg-color;
}

.shopping-cart-btn{
    background-color: white;
}

.chat-btn{
    background-color: white;
}