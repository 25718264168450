@import "variables";

.arrow-down {
  border: solid $primary-text-color;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  margin: 0 0 0 7px;
}

svg.question {
  fill: $primary-text-color;
  stroke: $primary-text-color;
}


.cart-small svg.shopping-bag {
  fill: $primary-bg-color;
  stroke: $primary-bg-color;
}

.cart-big svg.shopping-bag{
  fill:$primary-text-color;
  stroke: $primary-text-color;
}

svg.horse{
  fill: #4f4f4f;
}

svg.arrow-up{
  fill: #615e58;
}

svg.down-arrow{
  fill:#615e58;
}

svg.cross-in-cart{
  fill: gray;
}


svg g.city{
  fill:red;
}

.location-drawer{
  &__item{
    &:hover{
      background-color: pink;
    }
  }
}


.arrow-right {
  border: solid $primary-text-color;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin: 0 0 0 auto;
}

.dot-menu{
  &__dot{
    background-color: $primary-text-color;
  }
}

.BottomNav{
  svg.shopping-bag{
    fill:$primary-bg-color;
    stroke: $primary-bg-color;
  }
  svg.chat{
    fill: $primary-bg-color;
    stroke: $primary-text-color;
  }
}

.signin-btn{
  background-color: $primary-bg-color;
  border-color: $primary-bg-color
}

.email-login-btn{
  background-color:$secondary-bg-color;
  border-color: $secondary-bg-color;
}