@import "variables";

.primary-text-color{
    color: $primary-text-color;
}

.primary-bg-color{
    background-color: $primary-bg-color;
}

.secondary-bg-color{
    background-color: $secondary-bg-color;
}

.secondary-bg-hover-color{
    background-color: $secondary-bg-hover-color;
}