/*
--------------------------------------
  Chaldal Color Scheme
--------------------------------------
*/

// $primary-bg-color: #FDD670;
// $primary-bg-hover-color: #dfb547;
// $primary-text-color: #615e58;
// $primary-accent-color: white;

// $secondary-bg-color: #FF686E;
// $secondary-bg-hover-color: #e04f54;
// $secondary-bg-active-color: #af3e42;

// $cart-small-top-bg: #888a83;
// $cart-small-top-hover-bg: #55584d;
// $cart-big-handle-bg-color: #b7b7b7;


$primary-bg-color: #603813;
$primary-bg-hover-color: darken($primary-bg-color,3);
$primary-text-color: white;
$primary-accent-color: white;

$secondary-bg-color: green;
$secondary-bg-hover-color: red;
$secondary-bg-active-color: indigo;

$cart-small-top-bg: lime;
$cart-small-top-hover-bg: pink;
$cart-big-handle-bg-color: maroon;

$category-item-hover-gradient: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(208, 208, 208, 1) 100%
  );

$product-card-overlay-bg-color: rgba(80, 80, 80, 0.8);
$modal-overlay-bg-color:rgba(0, 0, 0, .4);

$header-z-index:100;
$modal-z-index:101;
$leftsidebar-z-index:100;

$header-height: 55px;



$cart-big-z-index: 100;
$cart-small-z-index: 100;