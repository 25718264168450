@import "variables";

.Cart {
  .cart-small {
    height: 75px;
    width: 65px;
    position: fixed;
    top: calc(50% - 75px);
    right: 0;
    box-shadow: 0 0 16px -1px rgb(0 0 0 / 75%);
    background-color: $cart-small-top-bg;
    opacity: 1;
    z-index: $cart-small-z-index;
    cursor: pointer;

    &:hover{
      background-color: $cart-small-top-hover-bg;
    }
    display: none;

    @media screen and (min-width:768px) {
      display: block;
    }

    .items-count{
      color: $primary-bg-color;
    }

    .cart-total{
      color: lighten($primary-text-color,4);
    }

  }

  .cart-big {
    position: fixed;
    top: 0;
    right: 0;
    height: calc(100% - 56px);
    box-sizing: border-box;
    box-shadow: -2px 0 12px 0 rgb(143 143 143 / 38%);
    width:100vw;
    height: 100vh;
    z-index: $cart-big-z-index;

    @media screen and (min-width:768px) {
      top:55px;
      width:320px;
    }



    .cart-handle {
      cursor: pointer;
      position: absolute;
      left: -16px;
      top: 45%;
      margin: auto;
      height: 70px;
      width: 16px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      color: #fff;
      background-color: $cart-big-handle-bg-color;
      border: 1px solid rgba(0, 0, 0, .1);

      &::before {
        content: "";
        width: 15px;
        height: 4px;
        background: #fff;
        display: block;
        position: absolute;
        transform: rotate(65deg);
        margin-top: 26px;
      }

      &::after {
        content: "";
        width: 15px;
        height: 4px;
        background: #fff;
        display: block;
        position: absolute;
        transform: rotate(-65deg);
        margin-top: 38px;
      }
    }
  }
}

.st0 {
  stroke: #4e4e4e;
  fill: #4e4e4e;
}


.arrow-up-circle{
  border-color:#787878
}

svg path.location-pointer{
  fill: green;
}