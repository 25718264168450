.orderHow {
    position: relative;
    overflow: hidden;
    height: 375px;
    width: 100%;
  
    .carousel {
      width: 300%;
      padding: 10px;
      position: absolute;
      height: 100%;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
  
      span {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        .carousel__item {
          width: 33.333333333333336%;
          height: 100%;
          background-position: 50% 50% !important;
          -webkit-background-size: contain !important;
          background-size: contain !important;
          display: inline-block;
          background-color: transparent;
          background-position: center top;
          background-repeat: no-repeat;
        }
  
      }
    }
  
    .carousel-controls-wrapper {
      position: absolute;
      bottom: 10px;
      right:20%;
      .carousel-controls li {
        
        bottom:10px;
        display: inline-block;
        padding: 0;
        width: 10px;
        height: 10px;
        background-color: #ddd;
        margin: 5px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        cursor: pointer;
        &.active{
          background-color: #ee4054;
        }
      }
    }
  }