@import "variables";

$lefsidebar-width: 220px;

.SiteBody {
  margin-top: calc($header-height + 30px);

  @media(min-width: 768px) {
    margin-top: $header-height;
  }
}

.LeftSidebar-Wrapper {
  position: fixed;
  left: 0;
  height: 100vh;
  width: $lefsidebar-width;
  background-color: white;
  overflow: auto;
  z-index: $leftsidebar-z-index;
  top: calc($header-height + 30px);
  @media(min-width: 768px) {
    top: $header-height;
  }
}

.LeftSidebar-Wrapper:not(.in-mobile-open):not(.in-desktop-open) {
  display: none;
}

.LeftSidebar-Wrapper.in-desktop-open:not(.in-mobile-open){
  display: none;
  @media (min-width:768px) {
    display: block;
  }
}

.LeftSidebar-Wrapper.in-mobile-open:not(.in-desktop-open){
  display: block;
  @media (min-width:768px) {
    display: none;
  }
}

.Mainbar-Wrapper {
  margin-left: 0px;

  &.with-sidebar-desktop {
    margin-left: 0;
    @media(min-width: 768px) {
      margin-left: $lefsidebar-width;
    }
  }
}



.products {
  padding: 12px;
}

.Cart-Wrapper {}


.loc_container {
  max-width: 550px;
  min-width: 150px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgb(0 0 0 / 31%);

  img {
    height: 100%;
    width: 100%;
    object-fit: none;
    object-position: 40%;
  }
}

.locations {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 550px));
}