@import "variables";

.Product {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 7px;
    text-transform: capitalize;

    &__overlay {
      display: none;
      cursor: pointer;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid lightgray;
      border-radius: 3px;
      position: absolute;
      height: 100%;
      width: 100%;
    background-color: $product-card-overlay-bg-color;

      &__info {
        display: flex;
        height: calc(100% - 25px);
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &__qtys {
          display: flex;
          justify-content: space-around;
          align-items: center;


          &__inc-btn {
            color: #fef3d5;
            border: 3px solid #fef3d5;
            border-radius: 50%;
            height: 33px;
            width: 33px;

            font-size: 35px;
            line-height: 19px;
            padding-top: 0;
            padding-left: 6px;

            &:hover {
              color: #fdd670;
              border-color: #fdd670;
            }
          }


          &__qty {
            font-size: 3em;
            color: #fdd670;
            margin: 0 20px;
          }

          &__dec-btn {
            color: #fef3d5;
            border: 3px solid #fef3d5;
            border-radius: 50%;
            height: 33px;
            width: 33px;

            font-size: 35px;
            line-height: 19px;
            padding-top: 0;
            padding-left: 2px;

            &:hover {
              color: #fdd670;
              border-color: #fdd670;
            }
          }
        }
      }

      &__btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 25px;
        background-color: #fff;
      }
    }
  }

  &__atc-btn {
    height: 37px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ebebeb;
    padding: 6px 0;
    text-align: center;
    transition: all ease-in-out 0.15s;
    width: inherit;
    color: #fe8280;
    border-radius: 3px;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 1px 1px #c3c1c199;
      border-color: #d0cfcf;
    }
  }

  &__incdec {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #ff8182;
    height: 37px;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    box-shadow: 1px 1px 1px rgb(204 204 204 / 50%);

    &__dec {
      width: 30px;
      border-right: 1px solid #d55f5f;
      font-weight: 500;

      &:active {
        box-shadow: inset 1px 1px 5px #ff1b1d;
      }
    }

    &__total {
      flex-grow: 1;
      font-weight: 600;
    }

    &__inc {
      width: 30px;
      font-weight: 500;
      border-left: 1px solid #d55f5f;

      &:active {
        box-shadow: inset 1px 1px 5px #ff1b1d;
      }
    }
  }
}

.Product__details:hover .Product__details__overlay {
  display: flex;
}