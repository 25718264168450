.Products {
  display: flex;

  flex-flow: row wrap;
}

.Products>div {
  margin: 7px;
}

.orContainer {
  width: 100%;
  text-align: center;
  position: relative;
}

.orContainer::before {
  position: absolute;
  top: 8px;
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  z-index: -1;
}



.orContainer span {
  background: #fff;
  display: block;
  width: 50px;
  margin: 0 auto;
  font-size: 15px;
}

.signin-close {
  position: relative;
  border: 0;
  margin-right: 5px;
  cursor: pointer;
  border-color: #444;
  width: 40px;
  height: 40px;
}

.signin-close:hover:after {
  border-color: #eb484b;
}

.signin-close:hover:before {
  border-color: #eb484b;
}

.signin-close::before {
  position: absolute;
  content: "";
  width: 25px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
  border-top: 2px solid #444;
}

.signin-close::after {
  position: absolute;
  content: "";
  width: 25px;
  cursor: pointer;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top: 2px solid #444;
}

.add-new-address:hover svg {
  fill: #ff686e;
}


.pin-location {
  position: relative;
  height: 150px;
  border-radius: 10px;
}

.pin-btn {
  position: absolute;
  top: 75px;
  z-index: 100;
}

.pin-location::after {
  content: " ";
  background-color: rgba(0, 0, 0, .4);
  width: 100%;
  border-radius: 10px;
  position: absolute;
  height: inherit;
  z-index: 99;
}

.select-area svg {
  width: 22px;
  height: 22px;
  fill: #c9c9c9;
  stroke: #c9c9c9;
}

.select-area svg:hover {
  fill: #828282;
  stroke: #828282;
}

.map-close {
  width: 28px;
  height: 28px;
  position: relative;
  border-radius: 50%;
  border: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, .16));
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.map-close:hover {
  background-color: #feebec;
}

.map-close .before {
  content: '';
  position: absolute;
  background-color: #ff666b;
  border-radius: 0;
  -webkit-transition: 350ms ease-out;
  -moz-transition: 350ms ease-out;
  -o-transition: 350ms ease-out;
  transition: 350ms ease-out;
  z-index: 0;
  width: 18px;
  height: 2px;
  top: 13px;
  left: 5px;
}

.map-close .after {
  content: '';
  position: absolute;
  background-color: #ff666b;
  border-radius: 0;
  -webkit-transition: 350ms ease-out;
  -moz-transition: 350ms ease-out;
  -o-transition: 350ms ease-out;
  transition: 350ms ease-out;
  z-index: 0;
  width: 2px;
  height: 18px;
  left: 13px;
  top: 5px;
}

.map-search {
  box-shadow: 0 0 6px rgb(0 0 0 / 16%);
  max-width: 410px;
  width: 100%;
  border-radius: 12px;
  margin: 12px 0 0 12px;
  background-color: white;
}

.map-search input {
  border-radius: 12px;
  box-shadow: 0 0 6px rgb(0 0 0 / 16%);
}

.pin-map {
  position: relative;
}

.pin-map .map-search {
  position: absolute;
  top: 0;
  left: 0;
}

.pin-map .map-close {
  position: absolute;
  top: 12px;
  right: 12px;
}

.order-details-btn {
  cursor: pointer;
}

.order-details-btn:hover svg {
  fill: black !important;
}

.order-details-btn:hover .order-details-text {
  color: black;
}
